import React from "react";
import { Container, Row, Col} from "react-bootstrap";
import {Link} from 'gatsby'
import { Section} from "../../components/Core";
import {StaticImage} from 'gatsby-plugin-image';


const BlogList = () => (
  <>
    {/* <!-- Blog section --> */}
    <Section className="position-relative" id='bloglistmarginleft' style={{paddingTop: 0}}>
    <Container >

    <Row className="align-items-center justify-content-center" id='bloglistwidth'>

    <Col xl='3' id='blogCol'>
      <Link to={'/academie/changer-de-banque-comment-faire/'} id='textOnHover'><div style={{border: '1px solid rgba(0,0,0,.125)'}}id='cardOnHover'>
      <StaticImage src='../../images/changer-de-banque.jpeg' width={900} height={600} alt='how to start a business' placeholder='tracedSVG' style={{marginLeft: -1}}/>
      <div style={{padding: 15}}><h3>
      Changer De Banque – Comment Faire ?
        </h3><p>
        Le changement de banque, ou mobilité bancaire, implique d’opérer la domiciliation bancaire au sein d’un nouvel ét ...
      </p></div></div></Link></Col>

      <Col xl='3' id='blogCol'>
      <Link to={'/academie/ouvrir-un-compte-bancaire-en-ligne/'} id='textOnHover'><div style={{border: '1px solid rgba(0,0,0,.125)'}}id='cardOnHover'>
      <StaticImage src='../../images/ouvrir-un-compte-en-banque.jpeg' width={900} height={600} alt='get more sales' placeholder='tracedSVG' style={{marginLeft: -1}}/>
      <div style={{padding: 15}}><h3>
      Ouvrir Un Compte Bancaire En Ligne
        </h3><p>
        L’ouverture d’un compte bancaire est indispensable, ne serait-ce que pour recevoir de l’argent ou effectuer des pai ...
      </p></div></div></Link></Col>

      <Col xl='3' id='blogCol'>
      <Link to={'/academie/cloturer-son-compte-bancaire-comment-faire/'} id='textOnHover'><div style={{border: '1px solid rgba(0,0,0,.125)'}}id='cardOnHover'>
      <StaticImage src='../../images/cloturer-un-compte-bancaire.jpeg' width={900} height={600} alt='make a website' placeholder='tracedSVG' style={{marginLeft: -1}}/>
      <div style={{padding: 15}}><h3>
      Clôturer Son Compte Bancaire – Guide
        </h3><p>
        Aujourd’hui, clôturer un compte bancaire est devenu plus simple grâce à l’intervention des pouvoirs publics et les m ...
      </p></div></div></Link></Col>

      <Col xl='3' id='blogCol'>
      <Link to={'/academie/erreurs-a-eviter-en-banque-en-ligne/'} id='textOnHover'><div style={{border: '1px solid rgba(0,0,0,.125)'}}id='cardOnHover'>
      <StaticImage src='../../images/erreurs-a-eviter-banque-en-ligne.jpeg' width={900} height={600} alt='new twitter account' placeholder='tracedSVG' style={{marginLeft: -1}}/>
      <div style={{padding: 15}}><h3>
      Erreurs À Éviter Avec Une Banque En Ligne
        </h3><p>
        Tarifs attractifs, offre de bienvenue, conseillers disponibles, simplicité, …. Faut-il se laisser séduire par les ban ...
      </p></div></div></Link></Col>


      <Col xl='3' id='blogCol'>
      <Link to={'/academie/quelle-banque-en-ligne-choisir/'} id='textOnHover'><div style={{border: '1px solid rgba(0,0,0,.125)'}}id='cardOnHover'>
      <StaticImage src='../../images/quelle-banque-en-ligne-choisir.jpeg' width={900} height={600}
      alt='paid newsletter' placeholder='tracedSVG' style={{marginLeft: -1}}/>
      <div style={{padding: 15}}><h3>
      Quelle Banque En Ligne Choisir ?
        </h3><p>
        Fortuneo, Boursorama, Monabanq, ING Direct …. Ces banques 100% en ligne envahissent le paysage ...
      </p></div></div></Link></Col>

      <Col xl='3' id='blogCol'>
      <Link to={'/academie/banques-en-ligne-le-comparatif/'} id='textOnHover'><div style={{border: '1px solid rgba(0,0,0,.125)'}}id='cardOnHover'>
      <StaticImage src='../../images/banque-en-ligne-comparatif.png' width={900} height={600} alt='dark kitchen' placeholder='tracedSVG' style={{marginLeft: -1}}/>
      <div style={{padding: 15}}><h3>
      Banques En Ligne – Le Comparatif
        </h3><p>
        Si les banques en ligne reviennent deux à trois fois moins cher que les banques traditionnelles, il n’en est pas ...
      </p></div></div></Link></Col>


      <Col xl='3' id='blogCol'>
      <Link to={'/academie/classement-des-banques-francaises-quels-criteres/'} id='textOnHover'><div style={{border: '1px solid rgba(0,0,0,.125)'}}id='cardOnHover'>
      <StaticImage src='../../images/classement-des-banques-francaise.jpeg' width={900} height={600} alt='new ideas' placeholder='tracedSVG' style={{marginLeft: -1}}/>
      <div style={{padding: 15}}><h3>
      Classement Des Banques Françaises
        </h3><p>
        Le secteur bancaire français est partagé entre plusieurs grandes enseignes de banques de détails. Par conséquen ...
      </p></div></div></Link></Col>

      <Col xl='3' id='blogCol'>
      <Link to={'/academie/choisir-sa-banque-quelle-est-la-moins-chere/'} id='textOnHover'><div style={{border: '1px solid rgba(0,0,0,.125)'}}id='cardOnHover'>
      <StaticImage src='../../images/choisir-ma-banque.jpeg' width={900} height={600} alt='learn to code' placeholder='tracedSVG' style={{marginLeft: 0}}/>
      <div style={{padding: 15}}><h3>
      Choisir Ma Banque – La Moins Chère ?
        </h3><p>
        Les tarifs des banques sont un des principaux critères, avec la qualité de service, de sélection de banque pour ouv ...
      </p></div></div></Link></Col>

     



      <Col xl='3' id='blogCol'>
      <Link to={'/academie/comparateur-de-banque-en-ligne-trouver-la-moins-chere/'} id='textOnHover'><div style={{border: '1px solid rgba(0,0,0,.125)'}}id='cardOnHover'>
      <StaticImage src='../../images/trouver-la-moins-chere.jpeg' width={900} height={600} alt='learn to code' placeholder='tracedSVG' style={{marginLeft: 0}}/>
      <div style={{padding: 15}}><h3>
      Comparateur De Banque En Ligne
        </h3><p>
        Avec un univers bancaire qui comporte de nombreuses lignes de tarification et une concurrence qui s’accroît avec ...
      </p></div></div></Link></Col>

      <Col xl='3' id='blogCol'>
      <Link to={'/academie/meilleure-banque-qui-choisir/'} id='textOnHover'><div style={{border: '1px solid rgba(0,0,0,.125)'}}id='cardOnHover'>
      <StaticImage src='../../images/meilleure-banque-qui-choisir.jpeg' width={900} height={600} alt='learn to code' placeholder='tracedSVG' style={{marginLeft: 0}}/>
      <div style={{padding: 15}}><h3>
      Meilleure Banque – Qui Choisir ?
        </h3><p>
        Parvenir à trouver la meilleure banque dans un monde bancaire qui regorge d’informations et dont la transparence ...
      </p></div></div></Link></Col>

      <Col xl='3' id='blogCol'>
      <Link to={'/academie/les-meilleures-banques-en-ligne/'} id='textOnHover'><div style={{border: '1px solid rgba(0,0,0,.125)'}}id='cardOnHover'>
      <StaticImage src='../../images/les-meilleures-banques-en-ligne.jpeg' width={900} height={600} alt='learn to code' placeholder='tracedSVG' style={{marginLeft: 0}}/>
      <div style={{padding: 15}}><h3>
      Les Meilleures Banques En Ligne
        </h3><p>
        Ouvrir un compte  bancaire en ligne vous y pensez … Vous avez décidé de sauter le pas, mais vous êtes un peu p ...
      </p></div></div></Link></Col>

      <Col xl='3' id='blogCol'>
      <Link to={'/academie/tarifs-des-banques-les-principaux-frais-bancaires/'} id='textOnHover'><div style={{border: '1px solid rgba(0,0,0,.125)'}}id='cardOnHover'>
      <StaticImage src='../../images/tarifs-des-banques.png' width={900} height={600} alt='learn to code' placeholder='tracedSVG' style={{marginLeft: 0}}/>
      <div style={{padding: 15}}><h3>
      Tarifs Des Banques – Les Principaux Frais Bancaires
        </h3><p>
        Soumises à des obligations réglementaires, les banques sont désormais contraintes d’être transparentes envers l ...
      </p></div></div></Link></Col>

      <Col xl='3' id='blogCol'>
      <Link to={'/academie/frais-de-tenue-de-compte-bancaire-peut-on-les-eviter/'} id='textOnHover'><div style={{border: '1px solid rgba(0,0,0,.125)'}}id='cardOnHover'>
      <StaticImage src='../../images/frais-de-tenue-de-banque.jpeg' width={900} height={600} alt='learn to code' placeholder='tracedSVG' style={{marginLeft: 0}}/>
      <div style={{padding: 15}}><h3>
      Frais De Tenue De Compte Bancaire
        </h3><p>
        Il fût un temps où les frais de tenue de compte n’étaient pas facturés… Ce temps était celui où la réglementation n ...
      </p></div></div></Link></Col>

      <Col xl='3' id='blogCol'>
      <Link to={'/academie/les-banques-et-les-paiements-hors-zone-euro-quels-couts/'} id='textOnHover'><div style={{border: '1px solid rgba(0,0,0,.125)'}}id='cardOnHover'>
      <StaticImage src='../../images/paiement-hors-zone-euro.jpeg' width={900} height={600} alt='learn to code' placeholder='tracedSVG' style={{marginLeft: 0}}/>
      <div style={{padding: 15}}><h3>
      Les Banques Et Les Paiements Hors Zone Euro
        </h3><p>
        Lorsqu’un client possède une carte de paiement internationale, il peut effectuer des retraits et des paiements hors ...
      </p></div></div></Link></Col>
      <Col xl='3' id='blogCol'>
      <Link to={'/academie/frais-de-decouvert-des-banques-quelle-est-la-moins-chere/'} id='textOnHover'><div style={{border: '1px solid rgba(0,0,0,.125)'}}id='cardOnHover'>
      <StaticImage src='../../images/frais-de-decouvert.jpeg' width={900} height={600} alt='learn to code' placeholder='tracedSVG' style={{marginLeft: 0}}/>
      <div style={{padding: 15}}><h3>
      Frais De Découvert Des Banques
        </h3><p>
        Sujet délicat, les frais de découvert ne sont pas toujours évidents dans les grilles tarifaires des établissements trad ...
      </p></div></div></Link></Col>

      <Col xl='3' id='blogCol'>
      <Link to={'/academie/cotisation-carte-bancaire-quelle-est-la-banque-la-moins-chere/'} id='textOnHover'><div style={{border: '1px solid rgba(0,0,0,.125)'}}id='cardOnHover'>
      <StaticImage src='../../images/cotisation-carte-bancaire.png' width={900} height={600} alt='learn to code' placeholder='tracedSVG' style={{marginLeft: 0}}/>
      <div style={{padding: 15}}><h3>
      Cotisation Des Cartes Bancaires
        </h3><p>
        C’est l’une des principales dépenses en matière bancaire lorsque l’on est client chez une banque traditionnelle, la ...
      </p></div></div></Link></Col>

      <Col xl='3' id='blogCol'>
      <Link to={'/academie/les-neo-banques-cest-quoi/'} id='textOnHover'><div style={{border: '1px solid rgba(0,0,0,.125)'}}id='cardOnHover'>
      <StaticImage src='../../images/les-neobanques-cest-quoi.jpeg' width={900} height={600} alt='learn to code' placeholder='tracedSVG' style={{marginLeft: 0}}/>
      <div style={{padding: 15}}><h3>
      Les Néo-banques – C’est Quoi ?
        </h3><p>
        Apparues dès 2009 aux Etats-Unis, les néo-banques, aussi appelées banques digitales ou 100 % mobile, ont pour ...
      </p></div></div></Link></Col>

      <Col xl='3' id='blogCol'>
      <Link to={'/academie/banque-a-distance/'} id='textOnHover'><div style={{border: '1px solid rgba(0,0,0,.125)'}}id='cardOnHover'>
      <StaticImage src='../../images/banque-a-distance.jpeg' width={900} height={600} alt='learn to code' placeholder='tracedSVG' style={{marginLeft: 0}}/>
      <div style={{padding: 15}}><h3>
      Banque À Distance = 100% En Ligne?
        </h3><p>
        Les banques à distance sont de plus en plus présentes dans le paysage bancaire. Les services proposés par les b ...
      </p></div></div></Link></Col>

      <Col xl='3' id='blogCol'>
      <Link to={'/academie/banques-traditionnelles-quelle-est-la-moins-chere/'} id='textOnHover'><div style={{border: '1px solid rgba(0,0,0,.125)'}}id='cardOnHover'>
      <StaticImage src='../../images/banques-traditionnelles.jpeg' width={900} height={600} alt='learn to code' placeholder='tracedSVG' style={{marginLeft: 0}}/>
      <div style={{padding: 15}}><h3>
    Banques Traditionnelles – La Moins Chère ?
        </h3><p>
        Lorsqu’un particulier privilégie une relation de proximité avec sa banque et la présence d’un conseiller dédié, il s’or ...
      </p></div></div></Link></Col>

      <Col xl='3' id='blogCol'>
      <Link to={'/academie/banques-en-ligne-laquelle-choisir/'} id='textOnHover'><div style={{border: '1px solid rgba(0,0,0,.125)'}}id='cardOnHover'>
      <StaticImage src='../../images/banque-en-ligne-laquelle-choisir.jpeg' width={900} height={600} alt='learn to code' placeholder='tracedSVG' style={{marginLeft: 0}}/>
      <div style={{padding: 15}}><h3>
      Banques En Ligne, Quelle Banque Choisir ?
        </h3><p>
        L’apparition des banques en ligne comme BforBank, Boursorama, Fortuneo, Hello Bank, ING Direct ou Monabanq ...
      </p></div></div></Link></Col>





    </Row>
    </Container>
      



    </Section>
  </>
);

export default BlogList;
