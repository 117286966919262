import React from "react";
import { Container, Breadcrumb} from "react-bootstrap";
import {Link} from 'gatsby'
import PageWrapper from "../../components/PageWrapper";
import { Section, Title} from "../../components/Core";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {Helmet} from "react-helmet";

import AcademieList from "../../sections/blog/AcademieList";

function startabusiness() {
    return (
        <>
        <PageWrapper footerDark>
    	<Helmet>
                <meta charSet="utf-8" />
                <title>Banque Académie</title>
                <meta name="description" content="
              Avant la banque en ligne il y'avait la banque à distance des banques traditionnelles. Qu'en est-t-il aujourdhui ? Y'a t-il vraiment une différence ?" />
        </Helmet>
      	<Header />
      	<Section>
      	<Container style={{maxWidth: 1280}}>
		  <Breadcrumb id='breadyTop'>
			<Breadcrumb.Item> <Link to={`/`} style={{color: 'black'}}>Accueil</Link></Breadcrumb.Item>
			<Breadcrumb.Item active id='bready'> Banque Académie</Breadcrumb.Item>
      	 </Breadcrumb>
        	<Title id='mobileTitle'>Banque Académie</Title>
			<AcademieList/>
		</Container>
		</Section>
		<Footer/>
      </PageWrapper>
    </>
    )
}

export default startabusiness
